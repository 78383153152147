import React from "react"
import logo from "../assets/images/logo.svg"
import { FaAlignRight } from "react-icons/fa"
import pageLinks from "../constants/links"
import { Link } from "gatsby"
const Navbar = ({ toggleSidebar }) => {
  return (
    <nav className="navbar">
      <div className="nav-center">
        <div className="nav-header">
          {/* <img src={logo} alt="web dev" /> */}
          <p><span style={{ color: "hsl(209, 61%, 16%)", fontSize: "2rem", fontWeight: "bold" }}>Abhishek</span> <span style={{ color: "hsl(184, 77%, 34%)", fontSize: "2rem", fontWeight: "bold" }}>Shukla</span></p>
          <button aria-label="toggle sidebar" type="button" className="toggle-btn" onClick={toggleSidebar}>
            <FaAlignRight />
          </button>
        </div>
        <div className="nav-links">
          {pageLinks.map(link => {
            return (
              <Link key={link.id} to={link.url}>
                {link.text}
              </Link>
            )
          })}
        </div>
      </div>
    </nav>
  )
}

export default Navbar